'use client';

import { captureMessage } from '@sentry/nextjs';
import { useEffect, type JSX } from 'react';

export const SentryCaptureNotFoundResources = (): JSX.Element => {
  useEffect(() => {
    document.body.addEventListener(
      'error',
      (event: Event) => {
        if (!(event.target instanceof Element)) return;
        if (['IMG', 'SCRIPT'].includes(event.target.tagName)) {
          const element = event.target as HTMLImageElement | HTMLScriptElement;
          captureMessage(
            `Не удалось загрузить ресурс ${event.target.tagName}: ${element.src}`,
            'warning',
          );
        }
      },
      true,
    );
  }, []);
  return <></>;
};
