import cn from 'classnames';
import React, { memo, useMemo } from 'react';
import styles from './column.module.scss';

export const checkOffsetExists = (offset?: number): boolean => {
  return typeof offset === 'number';
};

type TColumnWidth = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type TAlign = 'flex-start' | 'center' | 'flex-end';
type TMediaValue<T> = {
  s?: T;
  m?: T;
  l?: T;
  xl?: T;
};

type TSpan = {
  s: TColumnWidth | 'auto';
  m?: TColumnWidth | 'auto';
  l?: TColumnWidth | 'auto';
  xl?: TColumnWidth | 'auto';
};

type TOffset = {
  s?: TColumnWidth;
  m?: TColumnWidth;
  l?: TColumnWidth;
  xl?: TColumnWidth;
};

export type TColumnProps = {
  /**
   * Группа свойств, в которой указывается ширина колонки
   * в определенной контрольной точке
   */
  span: TSpan;
  /**
   * Группа свойств, в которой указывается отступ для колонки
   * в определенной контрольной точке
   */
  offset?: TOffset;
  /**
   *
   */
  children: React.ReactNode;
  /**
   * Пользовательский класс стилей
   */
  className?: string;
  /**
   * Вертикальное выравнивание
   */
  align?: TMediaValue<TAlign>;
  /**
   * Горизонтальное выравнивание
   */
  justify?: TMediaValue<TAlign>;
  /**
   * Порядок элемента
   */
  order?: TMediaValue<number>;
};

export const Column = memo(function Column({
  justify = {},
  align = {},
  order = {},
  ...props
}: TColumnProps): JSX.Element {
  const classes = useMemo(
    () =>
      Object.entries({
        ['align-self']: align,
        ['justify-self']: justify,
        order,
      }).flatMap(([cssProperty, values]: [string, object]) => {
        return Object.entries(values).map(
          ([media, value]: [string, string]) =>
            styles[`${cssProperty}-${value}-${media}`],
        );
      }),
    [align, justify, order],
  );

  return (
    <div
      className={cn(
        classes,
        styles[`span-${props.span.s}`],
        styles[`span-m-${props.span.m ?? ''}`],
        styles[`span-l-${props.span.l ?? ''}`],
        styles[`span-xl-${props.span.xl ?? ''}`],
        {
          [styles[`offset-${props.offset?.s ?? ''}`]]: checkOffsetExists(
            props.offset?.s,
          ),
          [styles[`offset-m-${props.offset?.m ?? ''}`]]: checkOffsetExists(
            props.offset?.m,
          ),
          [styles[`offset-l-${props.offset?.l ?? ''}`]]: checkOffsetExists(
            props.offset?.l,
          ),
          [styles[`offset-xl-${props.offset?.xl ?? ''}`]]: checkOffsetExists(
            props.offset?.xl,
          ),
          [String(props.className)]: !!props.className,
        },
      )}
    >
      {props.children}
    </div>
  );
});
