'use client';

import { usePrevious } from 'hooks';
import { usePathname } from 'next/navigation';
import { type JSX } from 'react';

/**
 * Компонент для записи истории по страницам
 * Нужен для корректной работы перемещения назад по истории браузера внутри домена
 */
export const NavigationHistory = (): JSX.Element => {
  const pathname = usePathname();
  const previous = usePrevious(pathname);

  return <meta name="__previous_route__" content={previous} />;
};

export default NavigationHistory;
