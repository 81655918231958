import cn from 'classnames';
import React, { memo, useMemo } from 'react';
import styles from './row.module.scss';

type TAlignItems = 'flex-start' | 'center' | 'stretch' | 'flex-end';
type TJustifyContent =
  | 'flex-start'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'flex-end';
type TMediaValue<T> = {
  s?: T;
  sm?: T;
  m?: T;
  l?: T;
  xl?: T;
};

export type TRowProps = {
  /**
   *
   */
  children: React.ReactNode;
  /**
   * Пользовательский класс стилей
   */
  className?: string;
  /**
   * Разворачивает направление
   */
  reverse?: boolean;
  /**
   * Отступы между колонок
   * Задается вертикальный и горизонтальный отступ
   */
  gap?: TMediaValue<number | [number, number]>;
  /**
   * Вертикальное выравнивание
   */
  align?: TMediaValue<TAlignItems>;
  /**
   * Горизонтальное выравнивание
   */
  justify?: TMediaValue<TJustifyContent>;
};

export const Row = memo(function Row({
  justify = {},
  align = {},
  ...props
}: TRowProps): JSX.Element {
  const classes = useMemo(
    () =>
      Object.entries({
        ['justify-content']: justify,
        ['align-items']: align,
      }).flatMap(([cssProperty, values]: [string, object]) => {
        return Object.entries(values).map(
          ([media, value]: [string, string]) =>
            styles[`${cssProperty}-${value}-${media}`],
        );
      }),
    [justify, align],
  );

  const gaps: Record<string, number | [number, number]> = useMemo(
    () => ({
      get s() {
        return props.gap?.s ?? 16;
      },
      get sm() {
        return props.gap?.sm ?? this?.s;
      },
      get m() {
        return props.gap?.m ?? this.sm;
      },

      get l() {
        return props.gap?.l ?? this.m;
      },
      get xl() {
        return props.gap?.xl ?? this.l;
      },
    }),
    [props.gap],
  );

  const offsets = Object.entries(gaps).reduce(
    (
      acc: Record<string, number>,
      [media, value]: [string, number | [number, number]],
    ) => {
      const [x = 16, y] = [value].flat();
      acc[`--offset-x-${media}`] = x;
      acc[`--offset-y-${media}`] = y ?? x;
      return acc;
    },
    {},
  );

  return (
    <div
      style={offsets}
      className={cn(styles.row, classes, {
        [String(props.className)]: !!props.className,
        [styles.reverse]: props.reverse,
      })}
    >
      {props.children}
    </div>
  );
});
