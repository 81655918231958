import cn from 'classnames';
import { FormCheckbox } from 'components/form';
import { Button, Spacer, Typo } from 'components/ui-kit';
import { Column, Row } from 'components/ui-kit/grid';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { EMAIL_REGEXP } from 'lib/validators/validators';
import Link from 'next/link';
import React, { memo, useMemo } from 'react';
import * as yup from 'yup';
import styles from './subscription-form.module.scss';

type TSubscriptionFormProps = {
  onSubscribe: (email: string) => Promise<void>;
  afterSubscribedHandler?: () => void;
  label?: string;
  checkboxLabel?: React.ReactNode;
  agreementErrorMessage?: string;
  emailErrorMessage?: string;
};

type TFormValues = {
  email: string;
  agreement: boolean;
};

export const SubscriptionForm = memo(function SubscriptionForm({
  onSubscribe,
  afterSubscribedHandler,
  label = 'Email',
  checkboxLabel,
  agreementErrorMessage,
  emailErrorMessage,
}: TSubscriptionFormProps): JSX.Element {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        agreement: yup
          .boolean()
          .oneOf([true], agreementErrorMessage ?? 'Нужно ваше согласие'),
        email: yup
          .string()
          .trim()
          .required('Пожалуйста, проверьте, правильно ли указана почта')
          .email(
            emailErrorMessage ??
              'Пожалуйста, проверьте, правильно ли указана почта',
          )
          .matches(EMAIL_REGEXP, 'Проверьте правильность ввода email'),
      }),
    [emailErrorMessage, agreementErrorMessage],
  );

  const handleSubmit = async (
    values: TFormValues,
    helpers: FormikHelpers<TFormValues>,
  ): Promise<void> => {
    try {
      await onSubscribe(values.email);
      afterSubscribedHandler?.();
    } catch (error: unknown) {
      helpers.setFieldError('email', (error as Error)?.message);
    }
  };

  return (
    <Row>
      <Column span={{ s: 12 }}>
        <Formik<TFormValues>
          initialValues={{ email: '', agreement: true }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({
            errors,
            touched,
            values,
            handleBlur,
            handleChange,
            isSubmitting,
          }: FormikProps<TFormValues>) => (
            <Form placeholder="">
              <div className={styles.subscriptionFormInputWrapper}>
                <Spacer size={16} />
                <input
                  name="email"
                  value={values.email}
                  onChange={() => void 0}
                  onInput={handleChange}
                  onBlur={handleBlur}
                  className={cn(styles.subscriptionFormInput, {
                    [styles.subscriptionFormInputInvalid]: !!(
                      touched.email && errors.email
                    ),
                  })}
                  placeholder={label}
                />
                {touched.email && errors.email ? (
                  <>
                    <Spacer size={8} />
                    <Typo variant="s" color="alert" weight="500">
                      {errors.email}
                    </Typo>
                  </>
                ) : null}
                <Spacer size={12} />

                <Button
                  variant="primary"
                  type="submit"
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                  isBlock
                >
                  Участвовать
                </Button>
                <Spacer size={12} />
                <FormCheckbox
                  name="agreement"
                  label={
                    checkboxLabel ? (
                      <>{checkboxLabel}</>
                    ) : (
                      <Typo variant="xm" color="gray400">
                        Соглашаюсь на&nbsp;рассылку, принимаю{' '}
                        <Link href="/concent" target="_blank">
                          условия обработки персональных данных
                        </Link>{' '}
                        и&nbsp;
                        <Link href="/privacy" target="_blank">
                          политики конфиденциальности
                        </Link>
                      </Typo>
                    )
                  }
                />
              </div>
            </Form>
          )}
        </Formik>
      </Column>
    </Row>
  );
});
