'use client';

import { captureException } from '@sentry/nextjs';
import { usePathname } from 'next/navigation';
import { useEffect, type JSX } from 'react';

export const SentryCaptureNotFoundPage = (): JSX.Element => {
  const path = usePathname();

  useEffect(() => {
    captureException(new Error(`Страница ${path} не найдена`));
  }, []);
  return <></>;
};
