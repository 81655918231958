'use client';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

export const useRouteChange = (handler: (url: string) => void): void => {
  const pathname = usePathname();
  useEffect(() => {
    handler(pathname);
  }, [pathname]);
};
