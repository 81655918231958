'use client';
import cn from 'classnames';
import { SimpleImage } from 'components/simple-image';
import { Typo } from 'components/ui-kit';
import Modal from 'components/ui-kit/modal/modal.component';
import { useMedia } from 'hooks';
import { SubscriptionTag, carrotService } from 'lib';
import { TCarrotEventCallbackData } from 'lib/services/carrot/carrotquest.types';
import { useCallback, useEffect, useState } from 'react';
import { SubscriptionForm } from './components/form.component';
import styles from './super-socks-lead-popup.module.scss';
import { Breakpoint } from '../../_shared';

const CARROT_EVENT_TRIGGER_OPEN_SUPER_SOCKS_LEAD_POPUP =
  'show-super-socks-lead-popup';

export const SuperSocksLeadPopup = (): JSX.Element => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const breakpoint = useMedia();
  const isMobile = breakpoint < Breakpoint.MEDIUM_SCREEN;

  const handeSubscription = (email: string): Promise<void> => {
    return carrotService.subscribePromo(
      email,
      SubscriptionTag.SUPER_SOCKS_PROMO,
    );
  };

  const afterSubscribedHandler = (): void => {
    setIsSubscribed(true);
  };

  const handleClose = (): void => setIsOpen(false);

  const handlePopupOpenEvent = useCallback(
    (data: TCarrotEventCallbackData): void => {
      if (data.event === CARROT_EVENT_TRIGGER_OPEN_SUPER_SOCKS_LEAD_POPUP) {
        setIsOpen(true);
      }
    },
    [setIsOpen],
  );

  useEffect(() => {
    carrotService.addCallback('event_tracked', handlePopupOpenEvent);
    return () => {
      carrotService.removeCallback('event_tracked', handlePopupOpenEvent);
    };
  }, [handlePopupOpenEvent]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className={styles.popup}
      closeClassName={styles.popupClose}
    >
      <div className={styles.imgContainer}>
        <SimpleImage
          src={
            isMobile
              ? '/images/popup/super-socks/super-socks-lead-popup-mob.jpg'
              : '/images/popup/super-socks/super-socks-lead-popup.jpg'
          }
          layout="fill"
          alt="Коллекция мононосков в подарок!"
        />
      </div>

      <div
        className={cn(styles.content, {
          [styles.contentCenter]: isSubscribed,
        })}
      >
        {isSubscribed ? (
          <>
            <Typo className={styles.header} weight="700">
              Отлично!
              <br />
              Вы&nbsp;участвуете в&nbsp;розыгрыше
            </Typo>
            <Typo variant="l">
              Итоги подведём 15&nbsp;декабря и&nbsp;поделимся результатами
              в&nbsp;письме. Надеемся, удача улыбнётся именно вам!
            </Typo>
          </>
        ) : (
          <>
            <Typo className={styles.header} weight="700">
              Коллекция мононосков в&nbsp;подарок!
            </Typo>
            <Typo variant="l">
              Подпишитесь на&nbsp;рассылку, чтобы участвовать в&nbsp;розыгрыше
              набора мононосков Точка х&nbsp;Super Socks.
            </Typo>
            <SubscriptionForm
              onSubscribe={handeSubscription}
              afterSubscribedHandler={afterSubscribedHandler}
              label="Введите email"
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default SuperSocksLeadPopup;
