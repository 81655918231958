import { isBrowser } from '@brand/utils';
import { useEffect, useRef } from 'react';
import { useRouteChange } from './use-route-change';

const DUMMY_FUNCTION = (): void => void 0;

export const useAutoAnalytics = (): void => {
  const sendEventRef = useRef<VoidFunction>(DUMMY_FUNCTION);
  useRouteChange(sendEventRef.current);

  async function activateAnalytics(): Promise<void> {
    const Analytics = (await import('@ft/auto-analytics')).autoAnalytics;

    if (!process.env.AUTO_ANALYTICS_SITE_ID || !process.env.AUTO_ANALYTICS_URL)
      return;

    Analytics.init({
      siteId: process.env.AUTO_ANALYTICS_SITE_ID,
      url: process.env.AUTO_ANALYTICS_URL,
    });

    function sendEvent(): void {
      Analytics.changePage();
    }

    if (sendEventRef.current === DUMMY_FUNCTION) {
      sendEvent();
      sendEventRef.current = sendEvent;
    }
  }

  useEffect(() => {
    if (!isBrowser()) return;
    void activateAnalytics();
  }, []);
};
