'use client';

import { Button, Typo } from 'components/ui-kit';
import { Link } from 'components/ui-kit-v2';
import { CookieService } from 'lib';
import { useEffect, useState, type JSX } from 'react';
import styles from './cookie-using-info.module.scss';

const COOKIE_USING_INFO = 'cookie-using-info';

export const CookieUsingInfo = (): JSX.Element | null => {
  const [acceptCookies, setAcceptCookies] = useState<boolean>(true);

  useEffect(() => {
    setAcceptCookies(!!CookieService.getValue(COOKIE_USING_INFO));
  }, []);

  const handleAccept = (): void => {
    CookieService.setValue(COOKIE_USING_INFO, 'true');
    setAcceptCookies(true);
  };

  if (!acceptCookies) {
    return (
      <div className={styles.wrapper}>
        <Typo type="div" className={styles.typo}>
          Оставаясь в&nbsp;Универмаге, вы&nbsp;соглашаетесь{' '}
          <br className={styles.lineBreak} /> на&nbsp;использование{' '}
          <Link href="/cookie" target="_blank">
            cookies.
          </Link>
        </Typo>
        <Button
          variant="primary"
          onClick={handleAccept}
          className={styles.button}
        >
          Хорошо
        </Button>
      </div>
    );
  }

  return null;
};
