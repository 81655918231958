import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"src\":[{\"path\":\"../public/fonts/TochkaSans-Regular.woff\",\"weight\":\"normal\",\"style\":\"normal\"},{\"path\":\"../public/fonts/TochkaSans-Regular.woff2\",\"weight\":\"normal\",\"style\":\"normal\"},{\"path\":\"../public/fonts/TochkaSans-Regular.ttf\",\"weight\":\"normal\",\"style\":\"normal\"},{\"path\":\"../public/fonts/TochkaSans-Light.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../public/fonts/TochkaSans-Light.woff\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../public/fonts/TochkaSans-Light.ttf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../public/fonts/TochkaSans-Medium.woff\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../public/fonts/TochkaSans-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../public/fonts/TochkaSans-Medium.ttf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../public/fonts/TochkaSans-Demi.woff\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../public/fonts/TochkaSans-Demi.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../public/fonts/TochkaSans-Demi.ttf\",\"weight\":\"600\",\"style\":\"normal\"}]}],\"variableName\":\"TochkaSans\"}");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/node_modules/next/font/local/target.css?{\"path\":\"src/fonts/appercu-pro.font.ts\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"variable\":\"--appercu-pro\",\"src\":[{\"path\":\"../../public/fonts/appercu-pro/apercu-regular-pro.woff\",\"weight\":\"normal\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/appercu-pro/apercu-regular-pro.woff2\",\"weight\":\"normal\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/appercu-pro/apercu-regular-pro.ttf\",\"weight\":\"normal\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/appercu-pro/apercu-medium-pro.woff\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/appercu-pro/apercu-medium-pro.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/appercu-pro/apercu-medium-pro.ttf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/appercu-pro/apercu-bold-pro.woff\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/appercu-pro/apercu-bold-pro.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/appercu-pro/apercu-bold-pro.ttf\",\"weight\":\"600\",\"style\":\"normal\"}]}],\"variableName\":\"AppercuProFont\"}");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/node_modules/next/font/local/target.css?{\"path\":\"src/fonts/tochka-sans.font.ts\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"variable\":\"--tochka-sans\",\"src\":[{\"path\":\"../../public/fonts/TochkaSans-Regular.woff\",\"weight\":\"normal\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/TochkaSans-Regular.woff2\",\"weight\":\"normal\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/TochkaSans-Regular.ttf\",\"weight\":\"normal\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/TochkaSans-Light.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/TochkaSans-Light.woff\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/TochkaSans-Light.ttf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/TochkaSans-Medium.woff\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/TochkaSans-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/TochkaSans-Medium.ttf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/TochkaSans-Demi.woff\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/TochkaSans-Demi.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/TochkaSans-Demi.ttf\",\"weight\":\"600\",\"style\":\"normal\"}]}],\"variableName\":\"TochkaSansFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/builds/brand/svc/ishop/ishop-frontend/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["ScriptGA","ScriptVK","ScriptYaMetrika"] */ "/builds/brand/svc/ishop/ishop-frontend/src/_shared/scripts/metrics.script.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AutoAnalytic"] */ "/builds/brand/svc/ishop/ishop-frontend/src/components/auto-analytic/auto-analytic.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RibbonProvider","useRibbonContext"] */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ribbon/providers/ribbon-providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Ribbon"] */ "/builds/brand/svc/ishop/ishop-frontend/src/components/ribbon/ribbon.component.tsx");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/modules/auth/auth-modal.component.tsx");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/modules/cart/components/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["CartProvider","useCartContext"] */ "/builds/brand/svc/ishop/ishop-frontend/src/modules/cart/providers/cart.provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookieUsingInfo"] */ "/builds/brand/svc/ishop/ishop-frontend/src/modules/cookie-using-info/cookie-using-info.component.tsx");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/modules/navigation/components/navigation-history.component.tsx");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/modules/new-lead-popup/new-lead-popup.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SentryCaptureNotFoundPage"] */ "/builds/brand/svc/ishop/ishop-frontend/src/modules/sentry/sentry-capture-not-found-page.componet.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SentryCaptureNotFoundResources"] */ "/builds/brand/svc/ishop/ishop-frontend/src/modules/sentry/sentry-capture-not-found-resources.tsx");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/modules/super-socks-lead-popup/super-socks-lead-popup.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserInfoProvider"] */ "/builds/brand/svc/ishop/ishop-frontend/src/providers/auth-provider/user-info-provider.tsx");
;
import(/* webpackMode: "eager" */ "/builds/brand/svc/ishop/ishop-frontend/src/styles/globals.scss");
